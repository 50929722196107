import React from "react"

class Top extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            deck: 26,
            want: 0,
            draw: 1,
            probability: 0,
        }
    }

    calculate = (deck, want, draw) => {
        let prob = 1
        for (let i = 0; i < draw; i ++) {
            prob  = prob * (deck - want - i) / (deck - i)
        }
        this.setState({probability: (1-prob)*100})
    }

    calculateDeck = (event) => {
        let deck = parseInt(event.target.value, 10)
        this.setState({deck: deck})
        this.calculate(deck, this.state.want, this.state.draw)
    }

    calculateWant = (event) => {
        let want = parseInt(event.target.value, 10)
        this.setState({want: want})
        this.calculate(this.state.deck, want, this.state.draw)
    }

    calculateDraw = (event) => {
        let draw = parseInt(event.target.value, 10)
        this.setState({draw: draw})
        this.calculate(this.state.deck, this.state.want, draw)
    }

    render () {
        return <form className="top" noValidate autoComplete="off">
            デッキ残枚数：<input onChange={this.calculateDeck} type="number" defaultValue="26" /><br />
            今引きしたいカード枚数：<input onChange={this.calculateWant} type="number" defaultValue="0" /><br />
            ドロー枚数：<input onChange={this.calculateDraw} type="number" defaultValue="1" /><br />
            今引きの確率は、{this.state.probability.toFixed(2)}%
        </form>
    }
}

export default Top