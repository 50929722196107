import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"

const MyButton = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .075)',
        border: '1px solid rgba(0, 0, 0, .125)',
        margin: 0,
    },
})(Button)

export default MyButton