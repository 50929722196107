import React from "react"

class SW extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sp: 0,
            damage: 0,
        }
    }

    calculatedamage = (event) => {
        let sp = parseInt(event.target.value, 10)
        if (isNaN(sp) || sp < 6) {
            this.setState({damage: 0})
        } else {
            this.setState({damage: Math.floor((sp-6) / 3)})
        }
    }

    calculatesp = (event) => {
        let damage = parseInt(event.target.value, 10)
        if (isNaN(damage) || damage < 0){
            this.setState({sp: 0})
        } else {
            this.setState({sp: damage * 3 + 6})
        }
    }

    render() {
        return <div>
            <form className="sw" noValidate autoComplete="off">
                <div>
                    <input id="sp_sw" label="SP" onChange={this.calculatedamage} type="number" defaultValue="6" />
                    SPならば、{this.state.damage}のダメージを与える
                </div>
                <div>
                    <input id="damage_sw" label="ダメージ" onChange={this.calculatesp} type="number" defaultValue="0" />
                    ダメージを与えるのに必要なSPは{this.state.sp}
                </div>
            </form>
        </div>
    }
}

export default SW