import React from "react"

class Rost extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            power: 8,
            freezed: 0,
            damage: 8,
        }
    }

    calculate = (power, freezed) => {
        this.setState({damage: power + freezed * 3})
    }

    calculatePower = (e) => {
        this.setState({power: parseInt(e.target.value, 10)})
        this.calculate(parseInt(e.target.value, 10), this.state.freezed)
    }
    calculateFreezed = (e) => {
        this.setState({freezed: parseInt(e.target.value, 10)})
        this.calculate(this.state.power, parseInt(e.target.value, 10))
    }

    render() {
        return <form className="rost" noValidate autoComplete="off">
            ロストのパワー：<input defaultValue="8" onChange={this.calculatePower} type="number"></input><br />
            凍結数：<input defaultValue="0" onChange={this.calculateFreezed} type="number"></input><br/>
            ダメージ：{this.state.damage}
        </form>
    }
}

export default Rost