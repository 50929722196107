import React from "react"

class Fairy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ff: 0,
            otogi: 0,
            turn: 1,
            damage: 0,
            healed: 0,
        }
    }

    calculate = (ff, otogi, turn) => {
        this.setState({
            damage: ff * otogi * 2 * turn,
            healed: otogi * turn,
        })
    }

    calculateFF = (event) => {
        this.setState({ff: parseInt(event.target.value, 10)})
        this.calculate(parseInt(event.target.value, 10), this.state.otogi, this.state.turn)
    }

    calculateOtogi = (event) => {
        this.setState({otogi: parseInt(event.target.value, 10)})
        this.calculate(this.state.ff, parseInt(event.target.value, 10), this.state.turn)
    }

    calculateTurn = (event) => {
        this.setState({turn: parseInt(event.target.value, 10)})
        this.calculate(this.state.turn, this.state.otogi, parseInt(event.target.value, 10))
    }

    render () {
        return <form className="fairy" noValidate autoComplete="off">
            フローラルフェアリーの数：<input onChange={this.calculateFF} type="number" defaultValue="0" /><br />
            オトギの数：<input onChange={this.calculateOtogi} type="number" defaultValue="0" /><br />
            経過ターン：<input onChange={this.calculateTurn} type="number" defaultValue="1" /><br />
            ダメージ：{this.state.damage}<br/>
            回復量：{this.state.healed}<br/>
        </form>
    }
}

export default Fairy