import React from "react"

class Gun extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            r: 0,
            f: 0,
            t: 0,
            damage: 0,
        }
    }

    calculate = (r, f, t) => {
        this.setState({damage: r * (f + 1) * (t + 1)})
    }

    calculateR = (event) => {
        this.setState({r: parseInt(event.target.value, 10)})
        this.calculate(parseInt(event.target.value, 10), this.state.f, this.state.t)
    }

    calculateF = (event) => {
        this.setState({f: parseInt(event.target.value, 10)})
        this.calculate(this.state.r, parseInt(event.target.value, 10), this.state.t)
    }

    calculateT = (event) => {
        this.setState({t: parseInt(event.target.value, 10)})
        this.calculate(this.state.r, this.state.f, parseInt(event.target.value, 10))
    }

    render() {
        return <form className="gun" noValidate autoComplete="off">
            乱射兵：<input onChange={this.calculateR} type="number" defaultValue="0"></input><br />
            フランシスカ：<input onChange={this.calculateF} type="number" defaultValue="0"></input><br />
            追撃兵：<input onChange={this.calculateT} type="number" defaultValue="0"></input><br />
            ダメージ: {this.state.damage}
        </form>
    }
}

export default Gun