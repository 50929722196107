import React from "react"
import { Accordion, AccordionSummary, AccordionDetails } from "../components/accordion"

import Layout from "../components/layout"

import SW from "../components/calc/sw"
import Gun from "../components/calc/gun"
import Rost from "../components/calc/rost"
import Grimoire from "../components/calc/grimoire"
import Fairy from "../components/calc/fairy"
import Cross from "../components/calc/cross"
import Draw from "../components/calc/draw"
import Mulligan from "../components/calc/mulligan"
import Top from "../components/calc/top"

import "./mystylesheet.css"

const IndexPage = () => (
  <Layout>
    <Accordion className="calc"><AccordionSummary><h2>どこまでドローできるかな？</h2></AccordionSummary><AccordionDetails><Draw></Draw></AccordionDetails></Accordion>
    <Accordion className="calc"><AccordionSummary><h2>マリガンで引ける確率は？</h2></AccordionSummary><AccordionDetails><Mulligan></Mulligan></AccordionDetails></Accordion>
    <Accordion className="calc"><AccordionSummary><h2>今引き算</h2></AccordionSummary><AccordionDetails><Top></Top></AccordionDetails></Accordion>
    <Accordion className="calc"><AccordionSummary><h2>スカイウィッチ算</h2></AccordionSummary><AccordionDetails><SW></SW></AccordionDetails></Accordion>
    <Accordion className="calc"><AccordionSummary><h2>銃士算</h2></AccordionSummary><AccordionDetails><Gun></Gun></AccordionDetails></Accordion>
    <Accordion className="calc"><AccordionSummary><h2>ロスト算</h2></AccordionSummary><AccordionDetails><Rost></Rost></AccordionDetails></Accordion>
    <Accordion className="calc"><AccordionSummary><h2>魔導書算</h2></AccordionSummary><AccordionDetails><Grimoire></Grimoire></AccordionDetails></Accordion>
    <Accordion className="calc"><AccordionSummary><h2>フローラルフェアリー算</h2></AccordionSummary><AccordionDetails><Fairy></Fairy></AccordionDetails></Accordion>
    <Accordion className="calc"><AccordionSummary><h2>支援兵算</h2></AccordionSummary><AccordionDetails><Cross></Cross></AccordionDetails></Accordion>
  </Layout>
)

export default IndexPage
