import React from "react"
import MyButton from "../button"

class Draw extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lisa: 0,
            nancy: 0,
            shall: 0,
            girl: 0,
            eva: 0,
            cat: 0,
            peeping: 4,
            hand: 4,
        }
    }

    calculate = () => {
        if (this.state.shall) {
            this.setState({
                peeping: this.state.cat + this.state.nancy * 3 + this.state.lisa * 2 + this.state.eva * 2 + this.state.shall + 3 + 1 + 4,
            })
        } else {
            this.setState({
                peeping: this.state.cat + this.state.nancy * 3 + this.state.lisa *2 + this.state.eva * 2 + 1 + 4, 
            })
        }
        this.setState({
            hand: this.state.nancy * 2 + this.state.lisa + this.state.girl + 4+1,
        })
    }
    
    setNancy = (event) => {
        this.setState({nancy: parseInt(event.target.value, 10)})
    }
    setLisa = (event) => {
        this.setState({lisa: parseInt(event.target.value, 10)})
    }
    setShall = (event) => {
        this.setState({shall: parseInt(event.target.value, 10)})
    }
    setGirl = (event) => {
        this.setState({girl: parseInt(event.target.value, 10)})
    }
    setEva = (event) => {
        this.setState({eva: parseInt(event.target.value, 10)})
    }
    setCat = (event) => {
        this.setState({cat: parseInt(event.target.value, 10)})
    }

    render () {
        return <div>
        <form className="draw" noValidate autoComplete="off">
                ナンシ、メリィの数：<input onChange={this.setNancy} type="number" defaultValue="0"/><br/>
                リサ、風読などの数：<input onChange={this.setLisa} type="number" defaultValue="0"/><br/>
                シャルの数：<input onChange={this.setShall} type="number" defaultValue="0"/><br/>
                森の少女の数：<input onChange={this.setGirl} type="number" defaultValue="0"/><br/>
                エヴァ、デッドの数：<input onChange={this.setEva} type="number" defaultValue="0"/><br/>
                ドローなしチャージャーの数：<input onChange={this.setCat} type="number" defaultValue="0"/><br/>
                <MyButton onClick={this.calculate}>計算！</MyButton>
            </form>
            <div>
                上から{this.state.peeping}枚を見ることができ、手札は{this.state.hand}枚になります。<br/>
            </div>
        </div> 
    }
}

export default Draw