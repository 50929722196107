import React from "react"

class Mulligan extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            probability0: 0,
            probability4: 0,
        }
    }

    calculate = (event) => {
        let want = parseInt(event.target.value, 10)
        this.setState({
            probability0: (1 - ((30-want)*(29-want)*(28-want)*(27-want))/(30*29*28*27))*100,
            probability4: (1 - ((30-want)*(29-want)*(28-want)*(27-want)*(26-want)*(25-want)*(24-want)*(23-want))/(30*29*28*27*26*25*24*23))*100,
        })
    }

    render () {
        return <div>
            <form className="mulligan" noValidate autoComplete="off">
                一巡目に引きたいカードの枚数：<input onChange={this.calculate} type="number" defaultValue="0" /><br/>
                マリガンなしの場合：{this.state.probability0.toFixed(2)}%<br/>
                マリガン４枚の場合：{this.state.probability4.toFixed(2)}%
            </form>
        </div>
    }
}

export default Mulligan