import React from "react"

class Cross extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            supporter: 1,
            cross: 1,
            gain: 0,
        }
    }

    calculate = (supporter, cross) => {
        this.setState({gain: supporter * (cross - 1) * 2})
    }

    calculateSup = (event) => {
        this.setState({supporter: parseInt(event.target.value, 10)})
        this.calculate(parseInt(event.target.value, 10), this.state.cross)
    }

    calculateCross = (event) => {
        this.setState({cross: parseInt(event.target.value, 10)})
        this.calculate(this.state.supporter, parseInt(event.target.value, 10))
    }

    render () {
        return <form className="cross" noValidate autoComplete="off">
            赤十字支援兵の数：<input onChange={this.calculateSup} type="number" defaultValue="1" /><br />
            赤十字の合計（支援兵含む）：<input onChange={this.calculateCross} type="number" defaultValue="1" /><br />
            回復SP：{this.state.gain}
        </form>
    }
}

export default Cross