import { withStyles } from "@material-ui/core/styles"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"

export const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        margin: 0,
    },
    expanded: {
        margin: 0,
    },
})(MuiAccordion)

export const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        margin: 0,
    },
    expanded: {
        margin: 0,
    },
})(MuiAccordionSummary)

export const AccordionDetails = withStyles({
    root: {
        borderLeft: '1px solid rgba(0, 0, 0, .125)',
        borderRight: '1px solid rgba(0, 0, 0, .125)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
    }
})(MuiAccordionDetails)