import React from "react"

class Grimoire extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            grimoire: 0,
            walkure: 0,
            damage: 0,
        }
    }
    
    calculate = (grimore, walkure) => {
        this.setState({damage: grimore * walkure * 2})
    }

    calculateG = (event) => {
        this.setState({grimoire: parseInt(event.target.value, 10)})
        this.calculate(parseInt(event.target.value, 10), this.state.walkure)
    }

    calculateW = (event) => {
        this.setState({walkure: parseInt(event.target.value, 10)})
        this.calculate(this.state.grimoire, parseInt(event.target.value, 10))
    }

    render() {
        return <form className="grimoire" noValidate autoComplete="off">
            魔導書の数：<input onChange={this.calculateG} type="number" defaultValue="0" /><br />
            ワルキューレの数：<input onChange={this.calculateW} type="number" defaultValue="0" /><br />
            ダメージ：{this.state.damage}
        </form>
    }
}

export default Grimoire